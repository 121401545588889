function App() {
  return (
    <div className="flex flex-col items-center justify-center text-center w-full font-serif tracking-wide font-bold text-5xl mt-8">
      <div className="mb-16 text-lg">marcuse.co</div>
      <div className="w-full flex-grow-1 flex-1">
        <div className="flex-grow-1 flex-1">
          <a href="https://www.flowstate.fm">
            <div className="hover:text-red-400 cursor-pointer py-12 transition-all hover:bg-red-50 px-24 rounded-lg">
              Flow State
            </div>
          </a>
          <a href="https://apps.apple.com/us/app/ez-meme/id1452703542">
            <div className="hover:text-purple-900 cursor-pointer py-12 transition-all hover:bg-purple-50 px-24 rounded-lg">
              EZ Meme
            </div>
          </a>
        </div>
        <div className="flex-grow-1 flex-1">
          <a href="https://www.tonalrecall.net/">
            <div className="hover:text-pink-500 cursor-pointer py-12 transition-all hover:bg-pink-50 px-24 rounded-lg">
              Tonal Recall
            </div>
          </a>
          <a href="https://www.timeshop.io/">
            <div className="hover:text-blue-600 cursor-pointer py-12 transition-all hover:bg-blue-50 px-24 rounded-lg">
              Timeshop
            </div>
          </a>
        </div>
      </div>
      <div className="text-xs pb-4 absolute bottom-0 w-full">
        Terms | Privacy |{" "}
        <a href="yooooo@marcuse.co" className="hover:text-red-700">
          Contact
        </a>
      </div>
    </div>
  );
}

export default App;
